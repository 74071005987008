
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Step extends Vue {
  private srcList: Array<string> = [
    require('../img/1/pc.jpg'),
    require('../img/1/mb.jpg'),
    require('../img/1/wx.jpg'),
    require('../img/1/dd.jpg'),
  ]
}
