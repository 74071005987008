
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'
import dayJs from 'dayjs'

@Component({
  components: {}
})
export default class DataList extends Vue {
  private data: any = []
  private list: any = []
  private form: any = {
    companyId: '',
    companyName: '',
    appid: '',
    aeskey: '',
    token: '',
    serverNode: '',
    serverUrl: '',
    visitorUrl: ''
  }

  private rules: any = {
    appid: [
      { required: true, message: 'appId不能为空', trigger:'blur'}
    ],
    companyName: [
      { required: true, message: '公司名称不能为空', trigger:'blur'}
    ],
    aeskey: [
      { required: true, message: 'aesKey不能为空', trigger:'blur'}
    ],
    token: [
      { required: true, message: 'token不能为空', trigger:'blur'}
    ]
  }
  private created() {
    this.requestData()
  }
  private async requestData() {
    const res: any = await api.getUserList()
    if (res.code === 200) {
      this.data = res.data.list;
    }
  }


  /**
   * 根据点击选中的公司id 查询详情
   * @param val
   * @private
   */
  private async handleCurrentChange(val: any) {
    // 遍历公司信息数组，根据companyId知道到数据
    for (let i = 0; i < this.data.length; i++) {
      if(this.data[i].companyId == val.companyId) {
        this.form.companyId = this.data[i].companyId;
        this.form.appid = this.data[i].appid;
        this.form.companyName = this.data[i].companyName;
        this.form.aeskey = this.data[i].aeskey;
        this.form.token = this.data[i].token;
        this.form.serverNode = this.data[i].serverNode;
        this.form.serverUrl = this.data[i].serverUrl;
        this.form.visitorUrl = this.data[i].visitorUrl;
      }
    }
  }

  /**
   * 更新操作
   * @private
   */
  private save() {
    // 获取表单对象
    const form = this.form;
    // 表单校验
    const Element:any = this.$refs.configForm
    Element.validate(async (valid: boolean) => {
      if (valid) {
        // 调用更新接口
        const result: any = await api.updateEchatConfigDataByCompId(form);
        if (result.code == 200) {
          this.$message({
            type:'success',
            message:'保存成功'
          });
          this.requestData();
        } else {
          this.$message({
            type:'error',
            message:'保存失败'
          });
        }
      }
    });
  }

  /**
   *  添加操作
   * @private
   */
  private addCompId() {
    this.$prompt('请输入公司ID', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^[A-Za-z0-9]+$]?/,
      inputErrorMessage: '输入格式只支持数字、英文字母'
    }).then(async (val: any) => {
      let companyId : string = val.value;
      let echatConfig = new EchatConfig();
      echatConfig.setCompId(companyId);
      const result: any = await api.addEchatConfigDataByCompId(echatConfig);
      if (result.code == 200) {
        this.$message({
          type:'success',
          message:'保存成功'
        });
        // 刷新公司id列表
        this.requestData();
        // 刷新公司配置
        this.form.companyId = companyId;
        this.form.appid = '';
        this.form.companyName = '';
        this.form.aeskey = '';
        this.form.token = '';
      }else if (result.code == 400){
        // todo: 公司id已存在
        this.$message({
          type:'error',
          message:'公司Id已存在'
        });
      }
    });
  }

  /**
   * 删除操作
   * @private
   */
  private async del() {
    // 校验删除信息
    if (!this.form.companyId) {
      this.$message({
        type:'error',
        message:'companyId不存在'
      });
      return;
    }

    const companyId: string = this.form.companyId;
    // 弹框确认是否执行删除操作
    this.$confirm('此操作将删除账号'+ companyId +'公司的所有配置', '提示',{
      confirmButtonText: '删除',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(async () => {
      // 删除数据
      const result: any = await api.deleteEchatConfigDataByCompId(this.form);
      if (result.code == 200) {
        this.$message({
          type:'success',
          message:'删除成功'
        });
      }
      // 刷新公司id列表
      this.requestData();
      // 刷新公司配置
      this.form.companyId = '';
      this.form.appid = '';
      this.form.companyName = '';
      this.form.aeskey = '';
      this.form.token = '';
    });
  }
}

class EchatConfig {
  // 公司id
  private companyId: string = '';

  setCompId(companyId: string): void {
    this.companyId = companyId;
  }

}
