
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Step extends Vue {
  private srcList: Array<string> = [
    require('../img/2/ly.jpg'),
    require('../img/2/lypz.jpg'),
    require('../img/2/jdz.jpg'),
    require('../img/2/yspz.jpg'),
  ]
}
