
import { Component, Vue } from 'vue-property-decorator'
import api from './api'
import dayJs from 'dayjs'

@Component({
  components: {}
})
export default class DataList extends Vue {
  private isPending: boolean = false
  private list: any = []
  private contentList: any = []
  private expandStatus:Boolean = false
  private searchObj:any = {}
  private info: any = {
    secret: '',
    receiveId: '',
    aesKey: '',
    token: '',
    vikaToken: '',
    vikaUserTable: '',
    vikaUserTableUrl: '',
    vikaExternalTable: '',
    companyId: '',
  }


  private expends:Array<String> = []

  private pageInfo:any = {
    pageNum:1,
    pageSize:20,
    total:0
  }
  private created() {
    this.requestData()
  }

  // 获取数据
  private async requestData() {
    this.isPending = true;
    const res: any = await api.getUserRels()
    if (res.code === 200) {
      this.list = res.data || []
    }else {
      this.$alert(res.msg, 'error', {
        confirmButtonText: '关闭'
      });
    }
    const resUrl: any = await api.getWeixinCompanyInfo()
    if (resUrl.code === 200) {
      console.log(resUrl.data)
      this.info = resUrl.data;
    }else {
      this.$alert(res.msg, 'error', {
        confirmButtonText: '关闭'
      });
    }
     this.isPending = false
  }

  // 刷新数据
  private async userRelsFlash() {
    this.isPending = true;
    const res: any = await api.getUserRelsFlash()
    if (res.code === 200) {
      this.list = res.data || []
    }else {
      this.$alert(res.msg, 'error', {
        confirmButtonText: '关闭'
      });
    }
    this.isPending = false;
  }

  private bindingVika() {
    console.log(this.info.vikaUserTableUrl)
    if (this.info.vikaUserTableUrl != null){
      window.open(this.info.vikaUserTableUrl);
    }else {
      this.$alert('您未配置维格表地址，请在「企微同步维格表配置」中配置', 'error', {
        confirmButtonText: '关闭'
      });
    }
  }

  private async syncUserRel() {
    this.isPending = true;
    const res: any = await api.syncUserRel()
    if (res.code === 200) {
      this.$message({
        type:'success',
        message:'同步成功'
      });
    } else {
      this.$alert(res.msg, 'error', {
        confirmButtonText: '关闭'
      });
    }
    this.isPending = false;
  }

  private async getSyncUserExternalUser(val: any ) {
    this.isPending = true;
    let weixinUserId: string;
    weixinUserId = val.weixinUserId;
    const res: any = await api.syncDataByWeixinUserId(weixinUserId);
    if (res.code == 200) {
      this.$message({
        type: 'success',
        message: '同步成功'
      });
      this.requestData();
    } else {
      this.$alert(res.msg, 'error', {
        confirmButtonText: '关闭'
      });
    }
    this.isPending = false;
  }

  private getVikaAvatar(vla: any) {
    return vla.vikaAvatar;
  }


}
