
import { Component, Vue } from 'vue-property-decorator'
import Step1 from './components/step1.vue'
import Step2 from './components/step2.vue'
import Step3 from './components/step3.vue'
import Step4 from './components/step4.vue'
import Step5 from './components/step5.vue'
import Step6 from './components/step8.vue'
@Component({
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
  },
})
export default class Test extends Vue {
  private currentTab: string = '5'
  private changeTab(tab: string) {
    this.currentTab = tab
  }
  private back() {
    this.$router.replace('zyEchat')
  }
  // private getCurrentComp() {
  //   return 'Step' + this.currentTab
  // }
}
