
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'
import dayJs from 'dayjs'

@Component({
  components: {}
})
export default class DataList extends Vue {
  private data: any = []
  private list: any = []
  private form: any = {
    encrypt: '',
    plainText:'{\n' +
        '   "hello":"hello"' +
        '\n}'
  }
  private encryptForm: any = {
    encrypt: '',
    plainText:''
  }
  private decodeForm: any = {
    plainText: ''
  }
  private metaData: any = {
    uid: '',
    grade: '',
    category: '',
    name: '',
    nickName: '',
    gender: '',
    age: '',
    birthday: '',
    maritalStatus: '',
    phone: '',
    qq: '',
    wechat: '',
    email: '',
    nation: '',
    province: '',
    city: '',
    address: '',
    photo: '',
    memo: '',
    c1: '',
    c2: '',
    c3: '',
    c4: '',
    c5: '',
    c6: '',
    c7: '',
    c8: '',
    c9: '',
    c10: '',
    c11: '',
    c12: '',
    c13: '',
    c14: '',
    c15: '',
    c16: '',
    c17: '',
    c18: '',
    c19: '',
    c20: ''
  }

  private rules: any = {
    plainText: [
      { required: true, message: '明文不能为空', trigger:'blur'}
    ],
  }
  private created() {
    this.requestData()

  }
  private async requestData() {
    const res: any = await api.getEchatConfigDataList()
    if (res.code === 200) {
      this.data = res.data.list;
    }
    this.form.companyId = "123";
  }


  /**
   * 根据点击选中的公司id 查询详情
   * @param val
   * @private
   */
  private async handleCurrentChange(val: any) {
    // 遍历公司信息数组，根据companyId知道到数据
    for (let i = 0; i < this.data.length; i++) {
      if(this.data[i].companyId == val.companyId) {
        this.form.companyId = this.data[i].companyId;
        this.form.appid = this.data[i].appid;
        this.form.companyName = this.data[i].companyName;
        this.form.aesKey = this.data[i].aesKey;
        this.form.token = this.data[i].token;
      }
    }
  }

  /**
   * 更新操作
   * @private
   */
  private async save() {
    const result: any = await api.getEncode({code:this.form.plainText});
    this.form.encrypt = result.data;
  }

  /**
   * 解密
   * @private
   */
  private async decode() {
    const result: any = await api.getDecode({code:this.encryptForm.encrypt});
    this.encryptForm.plainText = result.data;
  }

  /**
   * 获取Access_token
   * @private
   */
  private async getAccessToken() {
    const result: any = await api.getAccessToken();
    this.decodeForm.plainText = result.data;
  }

  /**
   *  添加操作
   * @private
   */
  private addCompId() {
    this.$prompt('请输入公司ID', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^[A-Za-z0-9]+$]?/,
      inputErrorMessage: '输入格式只支持数字、英文字母'
    }).then(async (val: any) => {
      let compId : string = val.value;
      let echatConfig = new EchatConfig();
      echatConfig.setCompId(compId);
      const result: any = await api.addEchatConfigDataByCompId(echatConfig);
      if (result.code == 200) {
        this.$message({
          type:'success',
          message:'保存成功'
        });
        // 刷新公司id列表
        this.requestData();
        // 刷新公司配置
        this.form.companyId = compId;
        this.form.appid = '';
        this.form.companyName = '';
        this.form.aesKey = '';
        this.form.token = '';
      }else if (result.code == 400){
        // todo: 公司id已存在
        this.$message({
          type:'error',
          message:'公司Id已存在'
        });
      }
    });
  }

  /**
   * 删除操作
   * @private
   */
  private async del() {
      // 刷新公司配置
      this.form.encrypt = '';
      this.form.plainText = '';
  }

  /**
   * 刷新加密工具
   * @private
   */
  private async encryptDel() {
    // 刷新公司配置
    this.encryptForm.encrypt = '';
    this.encryptForm.plainText = '';
  }
}

class EchatConfig {
  // 公司id
  private compId: string = '';

  setCompId(compId: string): void {
    this.compId = compId;
  }

}
