
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'

@Component({
  components: {}
})
export default class Test extends Vue {
  private async testRequest(){
  let res:any =await api.testRequest()
   if(res.code==200){
    this.$message({
        message: "提交成功",
        type: 'success'
      });
    }else{
        this.$message({
          message: JSON.parse(res.data),
          type: 'error'
        });
    }
    
  }
}
