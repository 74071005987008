
import { Component, Vue } from 'vue-property-decorator'
import Step1 from './components/step6.vue'
import Step2 from './components/step7.vue'
@Component({
  components: {
    Step1,
    Step2,
  },
})
export default class Test extends Vue {
  private currentTab: string = '1'
  private changeTab(tab: string) {
    this.currentTab = tab
  }
  private back() {
    this.$router.replace('zyEchat')
  }
}
