
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'
let ws: any
function createHash(hashLength: number): string {
  // 默认长度 24
  return Array.from(Array(Number(hashLength) || 24), () =>
    Math.floor(Math.random() * 36).toString(36)
  ).join('')
}
let randomId = createHash(8)
let user = {
  id: randomId,
  name: randomId
}
@Component({
  components: {},
  filters: {
    getDefaultSrc: function (val: string | null) {
      return val
        ? val
        : 'https://fuss10.elemecdn.com/d/e6/c4d93a3805b3ce3f323f7974e6f78jpeg.jpeg'
    }
  }
})
export default class TsTestPage extends Vue {
  public msg: string = ''
  public content: any = []
  public users: [] = []
  public user: any = user
  private src: string = ''
  private created() {
    // this.initSrc()
    this.initWebsocket()
  }
  initSrc(): void {
    new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(
          'https://fuss10.elemecdn.com/a/3f/3302e58f9a181d2509f3dc0fa68b0jpeg.jpeg'
        )
      }, 5000)
    }).then((res: any) => {
      this.src = res
    })
  }
  addTotal(num1: number = 1, num2: number = 2): number {
    return num1 + num2
  }
  initWebsocket(): void {
    let _this = this
    ws = new WebSocket('ws://192.168.66.117:3000')
    ws.onopen = function () {
      // Web Socket 已连接上，使用 send() 方法发送数据
      ws.send(JSON.stringify({ user: user, message: '连接成功', type: 'tip' }))
    }

    ws.onmessage = function (evt: any) {
      var received_msg = evt.data
      // alert('数据已接收...')
      var resData = JSON.parse(evt.data)
      console.log('接收消息', resData)
      switch (resData.type) {
        case 'tip': // 连接|关闭
        case 'msg': // 聊天内容
          _this.content.push(resData.data)
          break
        case 'users': // 当前在线人
          _this.users = resData.data
          break
      }
    }

    ws.onclose = function () {
      // 关闭 websocket
      alert('连接已关闭...')
    }
  }
  sendMsg() {
    if (this.msg) {
      ws.send(JSON.stringify({ user: user, message: this.msg, type: 'msg' }))
      this.msg = ''
    }
  }
}
