
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'
import dayJs from 'dayjs'

@Component({
  components: {}
})
export default class DataList extends Vue {
  private list: any = []
  private contentList: any = []
  private expandStatus:Boolean = false
  private searchObj:any = {}
  // 枚举类型
  // === Start ====
  // 工单属性
  private setJobAttribute(row: any, column: any) {
    return this.getJobAttribute(row.jobAttribute);
  }
  private getJobAttribute(val: Number) {
    switch (val) {
      case 1:
        return "内部工单";
      case 2:
        return "外部工单";
      default:
        return val;
    }
  }
  // 工单状态
  private setJobStatus(row: any, column: any) {
    return this.getJobStatus(row.status);
  }
  private getJobStatus(val: Number) {
    switch (val) {
      case 1:
        return "新建";
      case 2:
        return "已开启";
      case 3:
        return "待回应";
      case 4:
        return "已挂起";
      case 5:
        return "已解决";
      case 6:
        return "已关闭";
      default:
        return val;
    }
  }
  // 回复类型
  private setJobReplyType(row: any, column: any) {
    return this.getJobReplyType(row.status);
  }
  private getJobReplyType(val: Number) {
    switch (val) {
      case 1:
        return "内部回复";
      case 2:
        return "外部回复";
      case 3:
        return "内部回复和外部回复";
      default:
        return val;
    }
  }
  // 工单类型
  private getJobPriority(val: Number) {
    switch (val) {
      case 1:
        return "低";
      case 2:
        return "正常";
      case 3:
        return "高";
      case 4:
        return "紧急";
      default:
        return val;
    }
  }
  // 工单优先级
  private getJobType(val: Number) {
    switch (val) {
      case 1:
        return "问题";
      case 2:
        return "事务";
      case 3:
        return "故障";
      case 4:
        return "任务";
      default:
        return val;
    }
  }
  // 请求者
  private getJobRequesterUserType(val: Number) {
    switch (val) {
      case -1:
        return "客服";
      case 1:
        return "访客";
      default:
        return val;
    }
  }
  // 满意度评价等级
  private getJobMainItem(val: Number) {
    switch (val) {
      case 1:
        return "非常差评";
      case 2:
        return "不满意";
      case 3:
        return "一般";
      case 4:
        return "满意";
      case 5:
        return "非常满意";
      default:
        return val;
    }
  }
  // 工单指派分组
  private getJobDepartmentId(val: Number) {
    switch (val) {
      case 0:
        return "全部部门";
      default:
        return val;
    }
  }
  // 工单指派客服id
  private getJobDistributeUserId(val: Number) {
    switch (val) {
      case 0:
        return "由客服创建时，没有指定处理人；由访客创建时，工单自动分配为“手动分配”，即没有指定处理人";
      default:
        return val;
    }
  }
  // 工单更新人类型
  private getJobUpdaterType(val: Number) {
    switch (val) {
      case -1:
        return "客服";
      case 0:
        return "访客";
      default:
        return val;
    }
  }
  // 客户信息状态
  private getCrmStatus(val: String) {
    switch (val) {
      case "1":
        return "访客在信息收集窗口填写了数据，对话开始后自动触发";
      case "2":
        return "客服手动在一洽CRM系统填写了客户信息";
      case "3":
        return "客服分配客户";
      default:
        return val;
    }
  }
  // 访客身份类型 todo:公共
  private getVisitorType(val: Number) {
    switch (val) {
      case -1:
        return "未知";
      case 0:
        return "游客";
      case 1:
        return "会员";
      case 4:
        return "微信公众号渠道";
      case 5:
        return "自定义渠道";
      case 8:
        return "邮箱渠道";
      default:
        return val;
    }
  }
  // 客户端没媒介 todo:公共
  private getVisitorMedia(val: Number) {
    switch (val) {
      case 1:
        return "电脑端网页";
      case 2:
        return "移动端网页";
      case 5:
        return "微信";
      case 6:
        return "Android SDK";
      case 9:
        return "自定义渠道";
      case 11:
        return "邮箱渠道";
      default:
        return val;
    }
  }
  // 关键词类型 todo:公共
  private getKeywordType(val: Number) {
    switch (val) {
      case 0:
        return "未区分SEO、推广";
      case 1:
        return "自然优化";
      case 2:
        return "推广";
      default:
        return val;
    }
  }
  // 接入对话的路由ID todo:公共
  private getRouteId(val: Number) {
    switch (val) {
      case -1:
        return "web访客的默认路由";
      case -2:
        return "微信对话的默认路由";
      case -3:
        return "SDK对话的默认路由";
      case -7:
        return "自定义渠道的默认路由";
      default:
        return val;
    }
  }
  // 性别 todo:公共
  private getGender(val: Number) {
    switch (val) {
      case 0:
        return "未知";
      case 1:
        return "男";
      case 2:
        return "女";
      default:
        return val;
    }
  }
  // 婚姻状况 todo:公共
  private getMaritalStatus(val: Number) {
    switch (val) {
      case 0:
        return "未知";
      case 1:
        return "未婚";
      case 2:
        return "已婚";
      default:
        return val;
    }
  }
  // ===  End  ====
  private pickerOptions:any = {
    shortcuts: [{
      text: '最近一周',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近一个月',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
        picker.$emit('pick', [start, end]);
      }
    }, {
      text: '最近三个月',
      onClick(picker:any) {
        const end = new Date();
        const start = new Date();
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
        picker.$emit('pick', [start, end]);
      }
    }]
  }
  private expends:Array<String> = []
  private pageInfo:any = {
    pageNum:1,
    pageSize:20,
    total:0
  }
  private created() {
    this.requestData()
  }
  private async requestData() {
    interface Params {
      pageNum:Number,
      pageSize:Number,
      startDate?:String,
      endDate?:String
    }
    interface Res {
      errorCode: number // 返回数据类型状态码
      data: any // 返回数据
    }
    const params: Params = {
      pageNum:this.pageInfo.pageNum,
      pageSize:this.pageInfo.pageSize
    }
    const res: any = await api.getDataList(params)
    // console.log(res)
    if (res.code === 200) {
      this.pageInfo.total = 0
      this.list = res.data.list || []
      this.pageInfo.total = res.data.total
    }
  }
  private search() {
    // console.log(this.searchObj);

    // this.pageInfo.pageNum = 1
    // this.pageInfo.pageSize = 20
    // this.pageInfo.total = 0
    this.requestData()

  }

  private async del() {
    await api.delDataList();
    this.search()
  }

  private changePage(page:any){
    this.pageInfo.pageNum = page
    this.pageInfo.pageSize = 20
    this.requestData()
  }
  private changeExpend(val:boolean) {
    this.expandStatus = val
    if(!val){
      this.expends = []
    }else{
      this.expends = this.list.map((item:any)=>item.id)
    }
    // this.$refs.table.toggleRowExpansion()
  }
  private refresh() {
    this.requestData()
  }

}
