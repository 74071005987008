
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'
import dayJs from 'dayjs'

@Component({
  components: {}
})
export default class DataList extends Vue {
  private list: any = []
  private form: any = {
    url: 'https://eapi.echatsoft.com/csm/service/800/bIOkubOORVnBuZAqIfN',
    userId: '123456',
    userName: '彭伟标',
    userType: '1',
    echatTag: '自定义对话',
    msgType: 'text',
    chatPolicy: '111',
    text: 'hello,echat。we will be better'
  }
  private expandStatus: Boolean = false
  private searchObj: any = {}
  private pickerOptions: any = {
    shortcuts: [
      {
        text: '最近一周',
        onClick(picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近一个月',
        onClick(picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          picker.$emit('pick', [start, end])
        }
      },
      {
        text: '最近三个月',
        onClick(picker: any) {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          picker.$emit('pick', [start, end])
        }
      }
    ]
  }
  private expends: Array<String> = []
  private pageInfo: any = {
    pageNum: 1,
    pageSize: 20,
    total: 0
  }
  private created() {
    this.requestData()
  }
  private async requestData() {
    interface Params {
      pageNum: Number
      pageSize: Number
      startDate?: String
      endDate?: String
    }
    interface Res {
      errorCode: number // 返回数据类型状态码
      data: any // 返回数据
    }
    const params: Params = {
      pageNum: this.pageInfo.pageNum,
      pageSize: this.pageInfo.pageSize
    }
    if (
      this.searchObj.date &&
      this.searchObj.date[0] &&
      this.searchObj.date[1]
    ) {
      params.startDate = dayJs(this.searchObj.date[0]).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      params.endDate = dayJs(this.searchObj.date[1]).format(
        'YYYY-MM-DD HH:mm:ss'
      )
    }
    const res: any = await api.getDataList(params, {})
    // console.log(res)
    if (res.code === 200) {
      this.pageInfo.total = 0
      this.list = res.data.list || []
      this.pageInfo.total = res.data.total
    }
  }
  private search() {
    // console.log(this.searchObj);

    // this.pageInfo.pageNum = 1
    // this.pageInfo.pageSize = 20
    // this.pageInfo.total = 0
    this.requestData()
  }
  private changePage(page: any) {
    this.pageInfo.pageNum = page
    this.pageInfo.pageSize = 20
    this.requestData()
  }
  private changeExpend(val: boolean) {
    this.expandStatus = val
    if (!val) {
      this.expends = []
    } else {
      this.expends = this.list.map((item: any) => item.id)
    }
    // this.$refs.table.toggleRowExpansion()
  }
  private refresh() {
    this.requestData()
  }
}
