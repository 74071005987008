
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'
import dayJs from 'dayjs'

@Component({
  components: {}
})
export default class DataList extends Vue {
  private list: any = []
  private pageList: any =[]
  private expandStatus:Boolean = false
  private searchObj:any = {}
  private pickerOptions:any = {
          shortcuts: [{
            text: '最近一周',
            onClick(picker:any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker:any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker:any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        }
  private expends:Array<String> = []
  private pageInfo:any = {
    pageNum:1,
    pageSize:20,
    total:0
  }
  // 枚举类型
  // === Start ====
  // 记录类型
  private setChatRecordType(row: any, column: any) {
    return this.getChatRecordType(row.chatRecordType);
  }
  private getChatRecordType(val: Number) {
    switch (val) {
      case 1:
        return "人工对话";
      case 2:
        return "机器人对话";
      case 3:
        return "留言";
      case 4:
        return "推送";
      default:
        return val;
    }
  }
  // 对话事件类型
  private setChatEvent(row: any, column: any) {
    return this.getChatEvent(row.chatEvent);
  }
  private getChatEvent(val: Number) {
    switch (val) {
      case 1:
        return "点击图标";
      case 2:
        return "接受邀请对话";
      case 3:
        return "客服主动对话";
      case 4:
        return "微信输入任意文字接入对话";
      case 5:
        return "微信点击菜单接入";
      case 6:
        return "微信输入指定文字接入";
      case 7:
        return "微信机器人转人工";
      case 11:
        return "机器人转人工";
      case 12:
        return "机器人转留言";
      case 14:
        return "web自动弹屏自动接入";
      case 15:
        return "web自动弹屏访客输入消息接入";
      case 16:
        return "商户留言转平台接入";
      case 17:
        return "微信开发者接入";
      case 18:
        return "自定义渠道事件接入";
      case 19:
        return "自定义渠道发送消息接入";
      case 21:
        return "微信指定路由接入";
      case 26:
        return "邮箱渠道发送留言接入";
      default:
        return val;
    }
  }
  // 对话状态
  private getChatStatus(val: Number) {
    switch (val) {
      case 1:
        return "人工对话开始";
      case 2:
        return "人工对话结束";
      case 3:
        return "留言开始";
      case 4:
        return "留言结束";
      case 5:
        return "机器人对话开始";
      case 6:
        return "机器人对话结束";
      default:
        return val;
    }
  }
  // 是否存在转接
  private getChatIsTransfer(val: Number) {
    switch (val) {
      case 0:
        return "无转接";
      case 1:
        return "有转接";
      default:
        return val;
    }
  }
  // 对话类型
  private getChatType(val: Number) {
    switch (val) {
      case 1:
        return "在线对话";
      case 2:
        return "忙碌对话";
      case 3:
        return "离线消息";
      case 5:
        return "机器人对话";
      default:
        return val;
    }
  }
  // 对话类型
  private getChatScore(val: Number) {
    switch (val) {
      case 1:
        return "非常不满意";
      case 2:
        return "不满意";
      case 3:
        return "一般";
      case 4:
        return "满意";
      case 5:
        return "非常满意";
      default:
        return val;
    }
  }
  // 对话类型
  private getChatEndType(val: Number) {
    switch (val) {
      case 0:
        return "未知";
      case 1:
        return "访客结束对话";
      case 2:
        return "客服结束对话";
      case 3:
        return "访客超时";
      case 5:
        return "客服退出";
      case 6:
        return "系统关闭";
      case 8:
        return "访客长时间未回复结束";
      case 12:
        return "忙碌放弃结束";
      case 14:
        return "留言超时结束";
      case 15:
        return "机器人自动转人工结束";
      case 16:
        return "机器人手动转人工结束";
      case 18:
        return "客服主动对话后留言/机器人对话结束";
      case 19:
        return "机器人超时结束";
      default:
        return val;
    }
  }
  // 对话类型
  private getChatActivelyinviteEvaluating(val: Number) {
    switch (val) {
      case 0:
        return "没有邀评";
      case 1:
        return "有邀评";
      default:
        return val;
    }
  }
  // 对话分组id
  private getChatDepartmentId(val: Number) {
    switch (val) {
      case 0:
        return "全部人员可接";
      default:
        return val;
    }
  }
  // 对话类型
  private getStaffId(val: Number) {
    switch (val) {
      case -1:
        return "系统自动分配";
      default:
        return val;
    }
  }
  // 性别 todo:公共
  private getGender(val: Number) {
    switch (val) {
      case 0:
        return "未知";
      case 1:
        return "男";
      case 2:
        return "女";
      default:
        return val;
    }
  }
  // 婚姻状况 todo:公共
  private getMaritalStatus(val: Number) {
    switch (val) {
      case 0:
        return "未知";
      case 1:
        return "未婚";
      case 2:
        return "已婚";
      default:
        return val;
    }
  }
  private setVisitorMedia(row: any, column: any) {
    return this.getVisitorMedia(row.visitorMedia);
  }
  // 客户端没接 todo:公共
  private getVisitorMedia(val: Number) {
    switch (val) {
      case 1:
        return "电脑端网页";
      case 2:
        return "移动端网页";
      case 5:
        return "微信";
      case 6:
        return "Android SDK";
      case 9:
        return "自定义渠道";
      case 11:
        return "邮箱渠道";
      default:
        return val;
    }
  }
  // 访客身份类型 todo:公共
  private getVisitorType(val: Number) {
    switch (val) {
      case -1:
        return "未知";
      case 0:
        return "游客";
      case 1:
        return "会员";
      case 4:
        return "微信公众号渠道";
      case 5:
        return "自定义渠道";
      case 8:
        return "邮箱渠道";
      default:
        return val;
    }
  }
  // 关键词类型 todo:公共
  private getKeywordType(val: Number) {
    switch (val) {
      case 0:
        return "未区分SEO、推广";
      case 1:
        return "自然优化";
      case 2:
        return "推广";
      default:
        return val;
    }
  }
  // 接入对话的路由ID todo:公共
  private getRouteId(val: Number) {
    switch (val) {
      case -1:
        return "web访客的默认路由";
      case -2:
        return "微信对话的默认路由";
      case -3:
        return "SDK对话的默认路由";
      case -7:
        return "自定义渠道的默认路由";
      default:
        return val;
    }
  }



  private created() {
    this.requestData()
  }

  private async requestData() {
    interface Params {
      pageNum:Number,
      pageSize:Number,
      startDate?:String,
      endDate?:String
    }
    interface Res {
      errorCode: number // 返回数据类型状态码
      data: any // 返回数据
    }
    const params: Params = {
      pageNum:this.pageInfo.pageNum,
      pageSize:this.pageInfo.pageSize
    }
    const res: any = await api.getDataList(params)
    // console.log(res)
    if (res.code === 200) {
      this.pageInfo.total = 0
      this.list = res.data.list || []
      this.pageInfo.total = res.data.total
    }
  }

  private search() {
    // console.log(this.searchObj);
    
    // this.pageInfo.pageNum = 1
    // this.pageInfo.pageSize = 20
    // this.pageInfo.total = 0
    this.requestData()

  }
  // 根据对话记录ID 查询对话转接记录
  private async getTransfers(val: any) {
    interface Params {
      chatRecordId:Number
    }
    const params: Params = {
      chatRecordId:val.chatRecordId
    }
    const res: any = await api.getTransferList(params);
    this.pageList = res.data;
  }

  // 判断对话是否有转接记录
  private isTransfer(val: any) {
    return val.isTransfer == 0 ? true : false;
  }

  // 根据对话记录ID 查询对话客服kpi记录
  private async getStaffKpis(val: any) {
    interface Params {
      chatRecordId:Number
    }
    const params: Params = {
      chatRecordId:val.chatRecordId
    }
    const res: any = await api.getStaffKpiList(params);
    this.pageList = res.data;
  }

  private async del() {
    await api.delDataList();
    this.search()
  }

  private changePage(page:any){
    this.pageInfo.pageNum = page
    this.pageInfo.pageSize = 20
    this.requestData()
  }
  private changeExpend(val:boolean) {
    this.expandStatus = val
    if(!val){
      this.expends = []
    }else{
      this.expends = this.list.map((item:any)=>item.id)
    }
    // this.$refs.table.toggleRowExpansion()
  }
  private refresh() {
    this.requestData()
  }
  
}
