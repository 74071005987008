
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Test extends Vue {
  private go(type: number) {
    switch (type) {
      case 1:
        this.$router.push('AEchat1')
        break
      case 2:
        this.$router.push('BEchat2')
        break
    }
  }
}
