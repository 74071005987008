
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Step extends Vue {
  private srcList: Array<string> = [
    require('../img/4/jqr.jpg'),
    require('../img/4/pc.png'),
    require('../img/4/mb.png'),
    require('../img/4/ly.jpg'),
    require('../img/4/bd.jpg'),
    require('../img/4/gd.jpg'),
    require('../img/4/fwxx.png'),
  ]
}
