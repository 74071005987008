
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'

@Component({
  components: {}
})
export default class AdminList extends Vue {
  private list: any = []
  private created() {
    this.requestData()
  }
  private async requestData() {
    interface Params {
      dataType: string // 请求数据类型参数
    }
    interface Res {
      errorCode: number // 返回数据类型状态码
      data: any // 返回数据
    }
    const params: Params = {
      dataType: 'chatStart'
    }
    const res: any = await api.getDataList(params, {})
    // console.log(res)
    if (res.code === 200) {
      this.list = res.data || []
    }
  }
}
