
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Step extends Vue {
  private srcList: Array<string> = [
    require('../img/6/bb.jpg'),
    require('../img/6/dsjksh.jpg'),
  ]
  private afterImg: string = require('../img/6/3dmap.jpg')
  private openMap() {
    window.open('http://sz.safewaychina.cn:23208/h5/ext/advanced/#/map')
  }
}
