
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class Step extends Vue {
  private srcList: Array<string> = [
    require('../img/3/znfp.jpg'),
    require('../img/3/gjfp.jpg'),
    require('../img/3/lyfp.jpg'),
    require('../img/3/gdfp.jpg'),
  ]
}
