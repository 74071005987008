
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class TicketTest extends Vue {
  private title = ''
  private goTest() {
    this.$router.push('/test')
  }
  
}
