
import { Component, Vue } from 'vue-property-decorator'
import api from './api/index'
import dayJs from 'dayjs'

@Component({
  components: {}
})
export default class DataList extends Vue {
  private data: any = []
  private list: any = []
  private weixinForm: any = {
    secret: '',
    receiveId: '',
    aesKey: '',
    token: '',
    companyId: ''
  }
  private vikaForm: any = {
    vikaToken: '',
    vikaUserTable: '',
    vikaUserTableUrl: '',
    vikaExternalTable: ''
  }
  private weixinChatForm: any = {
    chatRecordSecret: ''
  }

  private weixinRules: any = {
    secret: [
      {required: true, message: 'secret不能为空', trigger: 'blur'}
    ],
    receiveId: [
      {required: true, message: '企业ID不能为空', trigger: 'blur'}
    ],
    aesKey: [
      {required: true, message: 'aesKey不能为空', trigger: 'blur'}
    ],
    token: [
      {required: true, message: 'token不能为空', trigger: 'blur'}
    ]
  }

  private vikaRules: any = {
    vikaToken: [
      {required: true, message: 'ApiToken不能为空', trigger: 'blur'}
    ],
    vikaUserTableUrl: [
      {required: true, message: '维格表「员工管理」地址不能为空', trigger: 'blur'}
    ],
    vikaUserTable: [
      {required: true, message: '「员工管理」表ID不能为空', trigger: 'blur'}
    ],
    vikaExternalTable: [
      {required: true, message: '「客户管理」表ID不能为空', trigger: 'blur'}
    ],
  }

  private weixinChatRules: any = {
    chatRecordSecret: [
      {required: true, message: 'Secret不能为空', trigger: 'blur'}
    ],
  }

  private created() {
    this.requestData()
  }

  private async requestData() {
    const res: any = await api.getWeixinCompanyInfo()
    if (res.code === 200) {
      this.weixinForm.secret = res.data.secret;
      this.weixinForm.receiveId = res.data.receiveId;
      this.weixinForm.aesKey = res.data.aesKey;
      this.weixinForm.token = res.data.token;
      this.weixinForm.companyId = res.data.companyId;
      this.vikaForm.vikaToken = res.data.vikaToken;
      this.vikaForm.vikaUserTable = res.data.vikaUserTable;
      this.vikaForm.vikaUserTableUrl = res.data.vikaUserTableUrl;
      this.vikaForm.vikaExternalTable = res.data.vikaExternalTable;
      this.weixinChatForm.chatRecordSecret = res.data.chatRecordSecret;
    }
  }


  /**
   * 更新操作
   * @private
   */
  private async save(type:string) {
    let form : any = {};
    // 表单校验
    let Element: any = {};
    if (type == 'weixin') {
      // 获取表单对象
      form = this.weixinForm;
      Element = this.$refs.weixinForm
    } else if (type == 'vika') {
      // 获取表单对象
      form = this.vikaForm;
      Element = this.$refs.vikaForm
    } else if (type == 'chat') {
      // 获取表单对象
      form = this.weixinChatForm;
      Element = this.$refs.weixinChatForm
    }
    Element.validate(async (valid: boolean) => {
      if (valid) {
        // 调用更新接口
        const result: any = await api.saveWeixinCompanyInfo(form);
        if (result.code == 200) {
          this.$message({
            type: 'success',
            message: '保存成功'
          });
          this.requestData();
        } else {
          this.$message({
            type: 'error',
            message: '保存失败'
          });
        }
      }
    });
  }
}
